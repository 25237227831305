.signup {
    background: linear-gradient(to bottom right, #00555C, black);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-height: 100%;
}

.signup-card {
    font-family: "Inter", sans-serif;
    color: white;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    flex-direction: column;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 20px;
    margin: auto;
    max-width: 400px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.15);
}

.signup-width {
    width: 360px;
}

.welcome-head {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.welcome-head h1 {
    font-size: 26px;
    font-weight: 600;
}

.welcome-head img {
    width: 60px;
    height: 60px;
    margin-right: 20px;
}

.description {
    font-size: 16px;
    color: #858585;
    font-weight: 600;
    text-align: center;
}

.signup-form {
    margin: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.signup-fields {
    display: flex;
    flex-direction: column;
    width: 360px;
    height: 66px;
    background-color: white;
    /* White background for light mode */
    padding: 8px 14px;
    font-size: 14px;
    border-radius: 6px;
    color: #212121;
    /* Text color for light mode */
    position: relative;
    margin-bottom: 10px;
}

.signup-fields label {
    margin-bottom: 4px;
    font-size: 10px;
    font-weight: 600;
    color: #212121;
    /* Text color for light mode */
}

.signup-fields input {
    background-color: white;
    /* White background for light mode */
    border: none;
    font-size: 16px;
    color: #212121;
    /* Text color for light mode */
}

.signup-fields input:focus {
    border: none;
    outline: none;
}

.signup-fields svg {
    position: absolute;
    top: 55%;
    right: 20px;
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.error-wrapper {
    padding-left: 8px;
    width: 100%;
    color: #ff2a2a;
    font-weight: 500
}

.SignUp-button {
    text-align: center;
    width: 360px;
    height: 55px;
    margin-bottom: 20px;
    cursor: pointer;
}

.SignUp-button button {
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background: linear-gradient(to right, #00555C, black);
    /* Gradient background for button */
    border: none;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    transition: background 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.2);
}

.SignUp-button button:hover {
    background: linear-gradient(to right, black, #00555C);
}

.SignUp-button svg {
    font-size: 20px;
    position: absolute;
    left: 70%;
}

.eye {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.dark-mode .signup {
    background: linear-gradient(to bottom right, #00555C, #111111);
}

.dark-mode .signup-card {
    background: rgba(0, 0, 0, 0.5);
}

.dark-mode .signup-fields {
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .signup-fields input {
    color: #ffffff;
}

.dark-mode .signup-fields svg {
    color: rgba(255, 255, 255, 0.7);
}

.dark-mode .SignUp-button button {
    background: linear-gradient(to right, #00555C, #111111);
}

.dark-mode .SignUp-button button:hover {
    background: linear-gradient(to right, #111111, #00555C);
}

.get_otp{
    text-align: right;
}
.react-tel-input .form-control {
    border: none;
    height: 28px;
    width: 100%;
}
input.form-control {
    /* border: none; */
    height: 28px;
    width: 100%;
}
.nav-tabs .nav-link {
    color: white;
    font-weight: 500;
}