.publish-main {
    border: 2px solid #00555C;
    background-color: #F9F6EE;
    padding: 28px;
    border-radius: 12px;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: start;
}

.publish-sub {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.publish-sub h4 {
    color: #00555C;
    margin-bottom: 15px;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 18px;
}

.publish-sub p {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 500;
}

.publish-details-button {
    width: 100%;
    background-color: #00555C;
    color: #F9F6EE;
    font-weight: 600;
    font-size: 14px;
}

.search-main {
    padding: 4px;
    align-items: center;
    display: flex;
    width: 220px;
    border: 1px solid #00555C;
    background-color: #F9F6EE;
    vertical-align: middle;
    border-radius: 12px;
    letter-spacing: 1px;
}

.btn-selected {
    background-color: #00555C;
    width: 120px;
    color: white;
}

.publish-title .btn {
    width: 120px;
}

.create-report-button {
    border: 1px solid #00555C;
    font-size: 14px;
}

.report-title {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    text-align: center;
}

.report-title-new{
   display: flex;
   justify-content: space-between;
}

.report-title h3 {
    font-size: 24px;
    font-weight: 600;
}

.report-title svg {
    font-size: 24px;
}

.report-button-create {
column-gap: 20px;
    display: flex;
    font-size: 18px;
}

.report-buttons{
    background-color: #A4D166;
    border-radius: 8px;
    padding: 8px 32px;
    color: black;
    border: 1px solid #A4D166;
    font-weight: 600;
    font-size: 14px;
}

.report-back{
  align-items: center;
  justify-content: center;
  column-gap: 12px;
}
.report-back h3{
font-size: 18px;
font-weight: 600;
}

.report-not-added {
    text-align: center;
    font-size: 18px;
    color: grey;
}

.create-report-selection {
    font-size: 16px;
    color: #00555C;
    font-weight: 600;
    margin-bottom: 60px !important;
}

.create-report-selection td {
    vertical-align: middle;
    text-align: center;
    padding: 10px 20px
}

.create-report-selection input {
    height: 18px;
    width: 18px;
    vertical-align: middle;
}

  /* .report-title h3 {
    margin-left: 10px;
    color: #007BFF;
  } */
  
  /* .report-title .back-arrow {
    font-size: 24px;
    cursor: pointer;
    color: #007BFF;
    transition: transform 0.2s;
  }
   */
  /* .report-title .back-arrow:hover {
    transform: rotate(-90deg);
  } */
  
  /* Card for Report Information */
  .report-card {
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  .report-card .card-body{
     height:100%
  }
  
  .report-details strong {
    font-size: 15px;
    color: #00555C;
  }
  .report-details p {
    color: rgb(44, 44, 44);
    font-weight: 500;
    font-size: 14px;
    margin: 8px 0;
    text-transform: capitalize;
  }
  