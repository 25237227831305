.dashboard{
    background-color: white;
    min-height: 100vh;
    border-radius: 40px;
}

.card-body {
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color);
    height: 160px;
}
.inspection{
    display: flex;
}
.card-text{
    font-weight: 600;
    font-size: 25px;
}
.card-title {
    align-items: center;
    font-size: 50px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    padding: 15px;
}
