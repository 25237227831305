.details{
    padding-bottom: 60px;
}
.details svg{
   cursor: pointer; 
   font-size: 18px;
}
.details-card{
    background-color: white;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.15);
    color: black;  
    padding: 20px;
    height: 160px;
    border-radius: 20px;
}
.details-card h3{
    font-size: 24px;
    text-transform: capitalize;
}
.details-card p{
    color: #4D4D4D;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
}
td img{
width: 30px;
height: 30px;
border-radius: 2px;
margin-right: 10px;
}
.details-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.details-card svg{
    font-size: 16px;
    margin-right:4px ;
}
.details-title{
    font-weight: 700;
    font-size: 24px;
    /* margin-top: 44px; */
}

.title-add{
   margin-top:4rem
}


.tackle-add input {
    padding: 5px;
    border-radius: 4px;
    width: 240px;
    text-align: center;
}

.tackle-add-multiplier input{
    padding: 5px;
    border-radius: 4px;
    width: 80px;
    text-align: center;
}

#limit{
    color: #CE0000;
}

.rules{
    margin: 24px 0;
    padding: 30px 15px;
    text-transform: capitalize;
    border: 1px solid #D7D7D7;
    border-radius: 12px;
}

.details table{
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.15);
}

.selected{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1px solid grey;
    padding: 8px 0px;
    font-size: 14px;
    font-weight: 500;
}
.checkbox-table{
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.15);
    color: black;  
    padding: 20px;
}

/* Styles for the checkbox */
.selected input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 22px;
    height: 22px;
    border: 2px solid #1A96F0;
    border-radius: 3px;
    margin-right: 5px;
    cursor: pointer;
    position: relative; /* Make sure the ::before and ::after pseudo-elements are positioned relative to the checkbox */
}

/* Styles for the checked state of the checkbox */
.selected input[type='checkbox']:checked {
    background-color: #fff; /* Change background color to white */
}

/* Styles for the checkmark icon */
.selected input[type='checkbox']::before {
    content: ''; /* Required for pseudo-elements */
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 11px;
    border: solid #1A96F0;
    border-width: 0 3px 3px 0;
    visibility: hidden; /* Initially hide the checkmark */
}

/* Styles for the checkmark icon when checkbox is checked */
.selected input[type='checkbox']:checked::before {
    visibility: visible; /* Show the checkmark */
}

/* Styles to rotate the checkmark icon */
.selected input[type='checkbox']:checked::before {
    transform: translate(-50%, -50%) rotate(45deg); /* Rotate the checkmark to form a tick */
    color: solid #212f3a;
}

/* Hide the default checkbox */
.selected input[type='checkbox']::after {
    content: '';
    display: none;
}

/*fish table*/

button#dropdown-status {
    background-color: #ffc107;
}

#dropdown-status{
    padding: 2px 8px;
    color: white;
    text-transform: capitalize;
    font-size: 14px;
}
.team-name{
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 600;
}
.no-fish{
    font-weight: 500;
    font-size: 14px;
}

.table>:not(caption)>*>* {
    text-transform: capitalize;
    font-weight: 500;
    color: #2c2c2c;
}
.totalPoints{
    margin: 0px 24px;
    font-weight: 600;
    font-size: 16px;
}
.points-table{
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.15);
}

.multipliers{
    /* max-width: 550px; */
    background-color: #4D4D4D;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.multiplier-head {
 background-color: #4D4D4D;
}