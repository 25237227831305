.page-link {
  margin: 6px;
  border-radius: 8px;
  color: #C0C0C0;
  border: 1px solid #C0C0C0;
}

.active>.page-link {
  background-color: #00555C;
  color: white;
  font-size: 14px;
  border: 1px solid #00555C;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}

.table {
  font-size: 14px;
  /* border-radius: 20px; */
  margin: 24px 0;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.table-page {
  display: flex;
}

.react-bootstrap-table {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.react-bootstrap-table th {
  background-color: #00555C;
  font-weight: 500;

}

.table thead th {
  color: white;
  text-align: center;
  background-color: #00555C;
  font-weight: 500;
}

/* Set border color for table rows */
.table tbody tr {
  border-color: #F6F6F6;
}

.table>thead {
  vertical-align: top;
  text-transform: capitalize;
}

.serial-number-header {
  width: 80px
}

.serial-number-column {
  width: 80px
}

.status-button-header {
  width: 180px
}

.status-button-column {
  width: 180px
}
.email-header{
  width: 150px
}
.email-column{
  text-transform: lowercase !important;
}

.project-status-button-header {
  width: 200px
}

.project-status-button-column {
  width: 200px
}

tr {
  /* text-align: center; */
  border-color: #F6F6F6;
  border-style: solid;
  border-width: 1px;
}

.details td {
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  padding: 10px 20px
}

label {
  margin: 5px 0;
  display: block ;
}

label .form-control {
  display: inline-block;
  font-size: 10px
}

.table-bordered>:not(caption)>*>* {
  border-width: 0;
}

.table-bordered>:not(caption)>* {
  border-width: none;
}

.react-bootstrap-table .filter input::placeholder {
  color: transparent;
}

.table>:not(caption)>*>* {
  padding: 1rem 0.5rem;
}
.projectbtn {
  display: flex;
  justify-content: space-between;
}

.Project-Button {
  width: 80%;
  background-color: #00555C;
  color: white;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  border: none;
}

.view-button {
  padding: 8px;
  width: 100%;
  background-color: #00555C;
  color: white;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  border: none;
  margin-right: 14px;
}

.view-fish-button{
  padding: 8px;
  width: 50%;
  background-color: #00555C;
  color: white;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  border: none;
  margin-right: 14px;
}

.view-user-button{
  padding: 8px;
  width: 50%;
  border: 1px solid #00555C;
  color: #00555C;
  background-color: white;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 14px;
}

.Count-Button {
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #00555C;
  background-color: white;
  border-radius: 50%;
  font-size: 14px;
  width: 36px;
  /* Adjust width */
  height: 36px;
  font-weight: 500;
  border: 1px solid #00555C;
}

.Count-Button.btn:hover {
  color: #00555C;
  background-color: white;
  border: 1px solid #00555C;
}


.entries-dropdown {
  display: flex;
  align-items: center;
}

.create-button {
  float: right;
  align-items: center;
  background-color: #00555C;
  color: white;
  display: flex;
  padding: 6px 12px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
}

.create-button p {
  margin: 0;
  padding-bottom: 0;
  padding-left: 8px;
}

.create-button svg {
  font-size: 12px;
}

.custom-total {
  font-size: 15px;
  font-weight: 500;
}

.in-progress {
  /* color: #FF7A00; */
  background-color: yellow;
  color: white;
  text-transform: capitalize;
  font-weight: 500;
}

.cancelled {
  color: #00C2FF;
  text-transform: capitalize;
  font-weight: 500;
}

.completed {
  color: #2F9300;
  text-transform: capitalize;
  font-weight: 500;
}

.active-badge {
  background-color: #28a745;
  /* Green color for 'active' status */
}

.draft-badge {
  background-color: #ffc107;
  /* Yellow color for 'draft' status */
}

.projecttable-head {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.projecttable-backarrow-div {
  display: flex;
  justify-content: space-between;
}

.user-card {
  display: flex;
}

.initial-letter {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  background-color: #00555C;
  color: #F6F6F6;
}

.first-letter img {
  width: 100px;
  height: 100px;

}

.user-details {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.user-details p {
  color: #6e6e6e;
  font-weight: 500;
}

.show-entries {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.show-entries select {
  margin-left: 10px;
  padding: 2px 6px;
  border-radius: 5px;
  background-color: #F6F6F6;
  border: none;
  font-size: 14px
}

.show-entries .form-control {
  float: right;
  width: 100%;
  font-size: 14px;
}

.pagination-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.entries {
  color: black;
  font-size: 16px;
  font-weight: 500;
}

.pagination {
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
}

.pagination button {
  margin: 0 5px;
}

.pagination-button.active {
  color: white;
  background-color: #00555C;
  border: 1px solid #00555C;
  padding: 2px 10px;
}

.pagination-button {
  background-color: white;
  color: #C0C0C0;
  border: 1px solid #C0C0C0;
  padding: 2px 10px;
}

.pagination p {
  color: #939393;
  font-size: 16px;
  font-weight: 500;
  margin: 0 5px;
}

.sr-only {
  display: none;
}

/* .active>.page-link {
      background-color: #00555C;
      color: white;
      border: none;
      font-size: 14px;
      padding: 5px 15px;
    } */
.count {
  display: flex;
  justify-content: center;
}

/* .delete-spinner{
    background-color: #F6F6F6;
  } */
.pagination-wrapper {
  display: flex;
  justify-content: space-between;
}

.pagination-controls {
  flex-grow: 1;
}

.pagination-and-entries {
  margin-left: 10px;
  /* Adjust as needed */
}

.export-button{
  color: white;
  background-color: #253f42;
  border: 1px solid #00555C;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.details h3 {
  color: #253f42;
  font-weight: 700;
}
.details h3 span{
  font-weight: 600;
  color: #00555C;
  text-transform: capitalize;
}
.details p {
  font-size: 18px;
  font-weight: 600;
}

.details p span {
  font-size: 14px;
  font-weight: 500;
}


.dark-mode .table tbody tr {
  background-color: #1d2020;
}

.dark-mode .entries {
  color: white;
}

.dark-mode .pagination button {
  color: black;
}

.dark-mode .pagination-button.active {
  background-color: #018995;
  color: #FFFBF8;
  font-weight: 600;
}

.dark-mode .show-entries .form-control {
  background-color: #1d2020;
  color: white;
}

.dark-mode #searchInput::placeholder {
  color: white;
  /* Change to your desired placeholder color */
}

.dark-mode .table td,
.dark-mode .table tbody {
  color: white;
  background-color: #1d2020;
  /* Change to your desired background color */
}

.dark-mode .table td,
.dark-mode .table tbody {
  border-color: transparent;
  /* Sets border color to transparent */
}

.status-pending-column{
  color:red
}

.approve-button{
  color:black;
  background-color: white;
  border: 1px solid #00555C;
  padding: 8px;
  width: 100%;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
}

.report-icon{
  font-size: 24px;
}
.report-checkbox-main{
  column-gap: 10px;
  align-items: center;
  display: flex;
}