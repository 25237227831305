* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  --secondary: #FE6F00;
  /* Define your secondary color variable */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

.pages {
  padding: 30px 60px;
  /* min-height: calc(100vh - 45px);
  background-color: #fff7f0; */
  min-height: 100vh;
  background-color: white;
}

.bellicon {
  background-color: white;
  border-bottom: 1px solid;
  border-color: #d8cfcf;
  height: 60px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 50px;
}
 
.back-arrow{
  font-size: 20px;
  cursor: pointer;
}

.user {
  margin-right: 30px;
  padding: none;
}
.user-profile{
  display: flex;
  justify-content: center;
  align-items: center;
  padding:8px 16px;
  border-radius: 5px;
  background-color: #E0EFF6;
}
.user-profile svg {
  margin-right: 5px;
  color: #00555C;
  font-size: 20px;
}

p {
  margin-bottom: 0;
}

/* Edit Profile */

.enter-address {
  background-color: white;
  /* padding: 45px 60px; */
}

/* .enter-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
} */
.profile-head {
  text-align: center;
}

.enter-address .form-label {
  font-weight: 500;
  font-size: 14px;
}

.enter-address .form-control {
  padding: 15px 15px;
  font-size: 14px;
}

.addbutton {
  display: flex;
  justify-content: center;
}

.add-btn {
  background-color: #FE6F00;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 70px;
  border-radius: 12px;
}

.upload {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid #cecece;
  background-color: #FAFAFA;
  font-size: 30px;
  color: #757575;
}

.profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.terms {
  display: flex;
  justify-content: center;
  background-color: white;
  text-align: center;
  min-height: 100vh;
  padding: 30px;
}

.verify-profile {
  position: absolute;
  top: 48px;
  right: 18px;
  color: red;
  font-size: 14px;
  cursor: pointer;
}
.btn{
cursor: pointer;
font-weight: 600;
}
.btn:hover {
  cursor: pointer;
  background-color: #e46708;
  border: 1px solid #e46708;
  color: white;
}

html,
body {
  height: 100%;
}

p {
  margin-bottom: 0;
}

.notification-container .notification-success {
  background-color: white;
  color: green;
  opacity: 1;
}

.notification-container .notification-error {
  background-color: white;
  color: red;
  opacity: 1;
}

.col_1_open {
  padding: 0;
  width: 22%;
  border-right: 1px solid;
  border-color: #D4D4D4;
}

.col_1_close {
  width: 4%;
  border-right: 1px solid #E5E5E5;
  padding-top: 20px;
  padding-left: 10px;
}

.col_2_open {
  background-color: #F5F5FA;
  position: relative;
  margin: 0;
  padding: 0;
  width: 78%;
}

.col_2_close {
  width: 96%;
  background-color: #F5F5FA;
}

.arrow-icon {
  color: #282828;
  border-radius: 50%;
  font-weight: 400;
  border: 1px solid #D9D9D9;
  font-size: 30px;
  padding: 5px;
  margin-right: 0px;
}

/*password*/

.pass {
  background-color: white;
}

.changepass {
  padding: 40px;
}

.changepass h2 {
  padding-bottom: 30px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.changepass .form-control {
  padding: 16px 32px;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  border: 1px solid #545454;
}

.passbutton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.passbtn {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding: 10px 15px;
  background-color: #FE6F00;
  color: white;
  border: none;
}

/*change password*/
.password_icon_wrap {
  position: absolute;
  top: 22px;
  left: 12px;
  color: #808080;
  font-size: 14px;
  margin-right: 6px;
}

.change-password_wrap {
  position: absolute;
  top: 22px;
  font-size: 14px;
  color: #808080;
  right: 16px;
}

.btn:disabled {
  background-color: #dd9c6a;
  color: white;
}

.btn.show {
  background-color: white;
  color: black;
}

.dropdown:hover {
  background-color: white;
}

.dropdown:active {
  background-color: white;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.center-flex{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.row-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .tabs .pages {
    padding: 30px 16px;
    height: 100%;
    background-color: #FFF7F0;
  }

  .cards {
    display: block !important;
  }

  .inspection-card {
    width: 100% !important;
    margin: 0px 0px 30px !important;
  }

  .renovation {
    background-color: white;
    border-radius: 8px;
    padding: 40px 16px;
    height: auto !important;
  }

  .emailbtn {
    float: none !important;
  }

  .terms {
    display: flex;
    justify-content: center;
    background-color: white;
    text-align: center;
    min-height: 100%;
    padding: 30px 16px;
  }

  .pages p {
    font-size: 14px;
    text-align: justify;
  }

  .sidebar {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 2;
    left: 0px;
    font-family: "Poppins", sans-serif;
    background: #fff;
  }

  .col_2_close {
    width: 100%;
    background-color: #F5F5FA;
  }

  .col_1_close {
    width: 0px;
    border-right: none;
    padding-top: 0px;
    padding-left: 5px;
  }

  .col_1_close svg {
    color: #282828;
    border-radius: 50%;
    font-weight: 400;
    border: 1px solid #D9D9D9;
    font-size: 30px;
    padding: 5px;
    margin-right: 0px;
    position: absolute;
    top: 14px;
    left: 16px;
  }

  .profile {
    padding: 40px 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tabs .pages {
    padding: 30px 16px;
    min-height: calc(100vh - 45px);
  }

  .terms {
    display: flex;
    justify-content: center;
    background-color: white;
    text-align: center;
    min-height: 100%;
    padding: 30px 16px;
  }

  .pages p {
    font-size: 14px;
    text-align: justify;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .col_1_close {
    width: 6%;
    border-right: 1px solid #E5E5E5;
    padding-top: 20px;
    padding-left: 10px;
  }

  .col_2_close {
    width: 94%;
    background-color: #F5F5FA;
  }

  .col_1_open {
    padding: 0;
    width: 30%;
    border-right: 1px solid;
    border-color: #D4D4D4;
  }

  .col_2_open {
    background-color: #F5F5FA;
    position: relative;
    margin: 0;
    padding: 0;
    width: 70%;
  }

  .sidebar {
    min-height: 100vh;
    height: 100%;
    width: 30%;
    position: fixed;
    z-index: 2;
    left: 0px;
    font-family: "Poppins", sans-serif;
  }

  .inspection-row {
    padding: 40px 15px;
    width: 100%;
  }

  .renovation {
    background-color: white;
    min-height: calc(100vh - 45px);
    border-radius: 8px;
    padding: 40px 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .col_1_close {
    width: 6%;
    border-right: 1px solid #E5E5E5;
    padding-top: 20px;
    padding-left: 10px;
  }

  .col_2_close {
    width: 94%;
    background-color: #F5F5FA;
  }

  .sidebar {
    min-height: 100vh;
    height: 100%;
    width: 30%;
    position: fixed;
    z-index: 2;
    left: 0px;
    font-family: "Poppins", sans-serif;
  }

  .col_1_open {
    padding: 0;
    width: 30%;
    border-right: 1px solid;
    border-color: #D4D4D4;
  }

  .col_2_open {
    background-color: #F5F5FA;
    position: relative;
    margin: 0;
    padding: 0;
    width: 70%;
  }

  .inspection-row {
    padding: 40px 15px;
    width: 100%;
  }
}

/*dark mode*/

.dark-mode .pages {
  background-color: #1d2020;
}

.dark-mode .bellicon {
  background-color: #1d2020;
}

.react-tel-input .form-control {
  border: none;
  height: 28px;
  width: 100%;
}

.SignUp-button button:hover{
border:none
}
input{
  font-weight: 500 !important;
  font-size: 15px;
}
.select-type-dropdown button#dropdown-basic{
  font-weight: 500;
  background-color: white;
}

table td{
  text-align: center;
}