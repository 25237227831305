.side {
    padding: 0;
  }
  
  .tabs {
    padding: 0;
  }
  
  .sidebar {
    background-color:#253f42;
    min-height: 100vh;
    height: 100%;
    width: 22%;
    position: fixed;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 2;
    left: 0px;
    font-family: "Poppins", sans-serif;
  }
  
  .sidebar-head {
    display: flex;
    align-items: center;
    padding-top: 18px;
    justify-content: space-between;
    padding-left: 20px;
  }
  
  .sidebar-head h2 {
    font-weight: 800;
    color: white;
    font-size: 18px;
    margin-bottom: 0;
  }
  
  .sidebar-head img {
    margin-right: 8px;
    height: 45px;
    width: 45px;
  }
  
  .sidebar ul {
    padding: 0;
    width: 100%;
    cursor: pointer;
    padding-top: 30px;
  }
  
  .sidebar ul li {
    display: flex;
    /* padding-left: 24px; */
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    color: #FFFBF8;
    font-weight: 600;
    margin: 0 20px;
    padding: 16px 32px;
  }
  .sidebar ul li svg{
     margin-right: 10px;
  }
  
  .sidebar ul a {
    margin: 0;
    font-size: 14px;
    text-decoration: none;
    color: #212121;
  }
  
  .sidebar svg {
    margin-right: 4px;
    color: white;
  }
  
  .sidebar ul li.active {
    background-color: #00555C;
    color: #FFFBF8;
    font-weight: 600;
    border-radius: 20px;
    margin: 0 20px;
    /* border-right: 2px inset #FE6F00; */
  }
  
  /* .sidebar ul li a.active {
    color: #FE6F00;
  } */

  .logout-user{
    display: flex;
    cursor: pointer;
    justify-content: center;
    color: white;
    margin: 40px;
    font-weight: 600;
    align-items: center;
  }
  
  @media only screen and (max-width: 767px) {
    .sidebar {
      min-height: 100%;
      border-right: 1px solid;
      border-color: #D4D4D4;
      height: 100%;
      width: 100%;
      font-family: "Poppins", sans-serif;
    }
    .sidebar-head {
      display: flex;
      padding: 16px 10px 16px 20px;
    }
    .sidebar ul {
      padding: 0;
      width: 100%;
      cursor: pointer;
      margin-bottom: 0px;
    }
    .bellicon {
      background-color: white;
      /* border-bottom: 1px solid;
      border-color: #D8CFCF; */
      display: flex;
      align-items: center;
      padding-left: 16px !important;
    }
    .sidebar ul li {
      display: flex;
      justify-content: flex-start;
      column-gap: 6px;
      align-items: center;
      cursor: pointer;
      padding: 16px 10px 16px 20px;
    }
    .user button {
      font-size: 14px;
    }
  }