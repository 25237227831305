.next-create-button {
    background-color: #00555C;
    color: #F3F3F3;
    padding: 8px 32px;
    float: right;
}

.species-points {
    width: 30%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.species-name {
    width: 70%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.species-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
}

.details-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.name-details-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.species-field input {
    background-color: #F9F6EE;
    border-radius: 10px;
    border: 1px solid #00555C;
    padding: 16px;
    width: 100%;
    height: 52px;
}

.edit-button {
    background-color: white;
    border: 1px solid #00555C;
    color: #00555C;
    font-size: 14px;
    padding: 4px;
    width: 80px;
    margin-right: 8px;
    font-weight: 600;
}

.add-button {
    background-color:#00555C;
    border: 1px solid ;
    color: white;
    font-size: 14px;
    padding: 4px;
    width: 80px;
    margin-right: 8px;
    font-weight: 600;
}
.delete-button {
    background-color: rgb(190, 4, 4);
    border: 1px solid rgb(190, 4, 4);
    color: white;
    font-size: 14px;
    padding: 4px;
    width: 80px;
    font-weight: 600;
}

.result-time {
    color: black !important;
    font-weight: 700;
}

.species-error {
    color: rgb(187, 11, 11);
    font-weight: 600;
    font-size: 16px;
    text-align: center;

}

.details-field input {
    background-color: #F9F6EE;
    border-radius: 10px;
    border: 1px solid #00555C;
    padding: 16px;
    width: 100%;
    height: 52px;
}

.species-field,
.details-field label {
    font-size: 16px;
    font-weight: 600;
    color: #00555C;
}

.details-field svg {
    font-size: 20px;
    color: #00555C;
    right: 22px;
    position: absolute;
    top: 50px;
}

.details-half {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
}

/*setup*/
.setup-main {
    margin-bottom: 40px;
}

.react-toggle-track-check svg {
    display: none;
}

.react-toggle-track-x svg {
    display: none;
}

.setup-half {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.setup-half p {
    color: #00555C;
    font-weight: 600;
    font-size: 16px;
}

.setup-main .multipliers {
    flex-direction: column;
    row-gap: 20px;
    display: flex;
    background-color: #F3F3F3;
    padding: 20px;
}

.setup-main th {
    text-align: left;
}

.setup-main tr {
    background-color: #F3F3F3;
    margin: 20px 0;
}

.setup-main td {
    padding: 0px;
    text-align: left;
}

.setup-main td input {
    padding: 8px;
    background-color: white;
    border: 1px solid #00555C;
    border-radius: 8px;
}

.setup-main svg {
    vertical-align: top;
}

.setup-checkbox {
    width: 24px;
    height: 24px;
    text-align: center !important;
}

.multipliers button {
    width: 120px;
    background-color: #A4D166;
    padding: 6px 12px;
    border: none;
    border-radius: 8px;
}

/* Hide the default number input spinners */
input[type='number'] {
    -moz-appearance: textfield;
    /* Firefox */
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    /* Safari and Chrome */
    margin: 0;
    /* Remove margin */
}

/* .custom-modal {
    width: 800px;
    display: flex;
    justify-content: center;
} */
.add-species-button {
    background-color: #A4D166;
    border-radius: 8px;
    padding: 8px 32px;
    color: black;
    border: 1px solid #A4D166;
    font-weight: 600;
    font-size: 14px;
}

.species-button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
}

.page-title {
    font-size: 24px;
    font-weight: 600;
}

.team-image-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    justify-content: center;
    width: 100%;
    height: 220px;
    border: 1px dotted black;
    background-color: #F9F6EE;
    border-radius: 30px;
}

.image-preview {
    height: 220px;
    position: relative;
    text-align: center;
    margin: auto;
}

.image-preview img {
    height: 220px;
    width: 220px;
    object-fit: cover;
}

.image-preview svg {
    position: absolute;
    font-size: 24px;
    background-color: white;
    border: 1px solid black;
    color: black;
    padding: auto;
    border-radius: 50%;
    top: 0;
    right: 120px;
}

.members-main .dropdown {
    width: 100%;
}

.select-type-dropdown button#dropdown-basic {
    justify-content: space-between;
    align-items: center;
    display: flex;
    color: black;
    text-align: left;
    width: 100%;
    cursor: pointer;
}

.members-main .dropdown-menu.show {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    display: flex;
}

.form-check-input[type=checkbox] {
    height: 18px;
    width: 18px;
    border-radius: .25em;
    margin-right: 20px;
}

.dropdown-menu.show {
    display: flex;
    width: 100% !important;
    flex-direction: column;
    align-items: center;
}

.dropdown-item {
    display: flex;
}

.details-field select {
    border-radius: 9px;
    padding: 10px;
    background-color: #F9F6EE;
    border-radius: 10px;
    border: 1px solid #00555C;
    word-wrap: normal;
}

.form-check-input:checked {
    background-color: #00555C;
    border-color: #00555C;
}

.rules-main textarea {
    background-color: #F9F6EE;
    border: 1px solid #00555C;
    border-radius: 20px;
    padding: 20px;
}

.submission-main {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #F3F3F3;
    padding: 90px;
    border-radius: 20px;
}

.custom-hr {
    border: none;
    /* Remove the default border */
    height: 2px;
    /* Set the height */
    background-color: black;
    /* Set your desired color */
}

.submission-sub h2 {
    color: #00555C;
    font-weight: 600;


}

.submission-sub {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    /* width: 550px; */
    background-color: white;
    padding: 30px 120px;
    border-radius: 20px;
}

.submission-sub p {
    color: #4D4D4D;
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 12px;
}

.submission-main button {
    background-color: #00555C;
    font-weight: 600;
}

.publish-later {
    background-color: white;
    border: 1px solid #00555C;
    color: #00555C;
    font-weight: 600;
    padding: 16px 32px;
    margin-right: 10px;
}

.publish-now {
    background-color: #00555C;
    border: 1px solid #00555C;
    color: white;
    font-weight: 600;
    padding: 16px 32px;
}

.back-create-button {
    background-color: #00555C;
    padding: 8px 32px;
    float: left;
}

.publish-title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
}

/* .submission-modal .modal-body{
   
} */
.submission-modal .modal-content {
    padding: 20px !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.age-main {
    display: flex;
    column-gap: 20px;
    margin: 10px 50px;
    justify-content: space-between;
}

.age-main p {
    font-size: 16px;
    font-weight: 600;
}

.age-main input {
    text-align: center;
    border-radius: 4px;
    border: 1px solid black;
    height: 30px;
    width: 60px;
}

.max-age {
    display: flex;
    column-gap: 10px;
    justify-content: space-between;
}

.max-age p {
    color: grey;
}

.add-category-main {
    display: flex;
    justify-content: space-around;
}

.add-category-main input {
    padding: 5px;
    border-radius: 4px;
}

.category-display {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    font-size: 14px;
}

.category-display h6 {
    font-weight: bold;
    margin: 0;
}

.category-display p {
    margin: 0;
    font-weight: normal;
    font-size: 12px;
    color: rgb(83, 83, 83);
    font-weight: 600;
}

.react-select__option {
    padding: 10px;
    background-color: #00555C;

}

.react-select__option--is-selected {
    background-color: #007bff;
    color: white;
}

.category-option-display {
    padding: 5px 10px;
}

.category-display label {
    font-size: 16px;
    font-weight: 600;
    color: #00555C;
}

.category-display .css-13cymwt-control {
    background-color: #F9F6EE;
    border-radius: 10px;
    border: 1px solid #00555C;
    padding: 4px 8px;
    color: black;
}