.modal-header, .modal-footer{
    border: none;
    padding-bottom: 0;
}
.report-modal .modal-header .btn-close{
    border-radius: 50%;
    background-color: #F5F5F5;
    color: #A0A0A0;
    padding: 14px;
}
.report-modal .modal-content{
    width: 100%;
    border-radius: 20px;
    font-family: "Poppins",sans-serif;
} 
.report-modal .modal-body{
    padding:12px 24px;
} 
.report-modal .form-label {
    font-size: 18px;
    font-weight: 600;
}
.report-modal .form-control{
    padding: 12px 14px;
    font-size: 14px;
    color: #606060;
}
.report-submit-btn{
    width: 100%;
    background-color:#00555C ;
    border: none;
    padding: 14px 0;
    margin-bottom: 30px;
    color: white;
    border-radius: 12px;
    font-weight: 600;
}
.upload-report{
    width: 100%;
    height: 200px;
    background-color: #FFFAF6;
    border: 1px #00555C solid;
    border-style: dashed;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.upload-report h3{
    font-size: 16px;
    font-weight: 500;
}
.upload-report p{
    font-size: 14px;
    color: #8D8D8D;
}

.agent-modal .modal-body{
    font-family: "Poppins",sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4rem 0;
}
.agent-modal .modal-body img{
    width:150px;
    height:150px;
}
.agent-modal .modal-body h2{
    font-size: 34px;
    font-weight: 600;
    color: #00555C;
}
.agent-modal .modal-body p{
    color: #606060;
    text-align: center;
    font-size: 20px;
}
.agent-submit-btn{
    padding: 14px 42px;
    background-color: #00555C;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 10px;
}

/* Logout Modal */
.report-modal p{
    font-weight: 500;
    text-align: center;
    font-size:22px;
    margin-bottom: 20px;
}

.report-modal .logout-model{
    padding: 50px 0;
}
.report-modal h6{
    font-size:18px;
}
/*delete account*/

.delete .form-control{
    width: 80%;
    color: black;
}
.delete_wrap {
    position: absolute;
    top: 16px;
    right: 60px;
    color: #808080;
    font-size: 14px;
}
.btn-confirm{
    color: #00555C;
    background-color: white;
    padding: 16px 32px;
    border: 1.2px #00555C solid;
    font-size: 18px;
    font-weight: 600;
    border-radius: 7px;
    margin-right: 1.2rem ;
}
.delete-spinner{
    margin: 0px 32px;
    height: 1.2rem;
    width: 1.2rem;
}
.report-modal .btn-confirm:hover{
    background-color:  #00555C;
    border: 1.2px solid #00555C ;
}
.btn-cancel{
   background-color: #00555C;
    color: white;
    padding: 16px 32px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-radius: 7px;
}

/*invite-modal*/
.invite-modal .modal-body{
    font-family: "Poppins",sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4rem 0;
}
.invite-modal .modal-body img{
    width:150px;
    height:150px;
}
.invite-modal .modal-body p{
    color: #00555C;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
}
.invite-submit-btn{
    padding: 16px 46px;
    background-color: #00555C;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 10px;
}


